import { useState, useEffect, useContext } from 'react';
import * as Property from "../../components/Properties";
import { setProperty } from '../../utils/property';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { usePropertyToLabel } from '../usePropertyToLabel';
import { useLabelUpdate } from '../useLabelUpdate';
import { alignmentOptions } from '../../utils/constants';
import { useWorkflowResources } from '../../hooks/useWorkflowResources';
import { useProduct } from '../../hooks/useProduct';
import TextLicense from '../../components/textLicense';

export default function Properties({ initData, actions }) {
  const [data, setData] = useState();
  useEffect(() => {
    setData(_.cloneDeep(initData))
  }, [initData])
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)
  const labelUpdate = useLabelUpdate(data, setData, actions.handleChanges)
  const propertyToLabel = usePropertyToLabel(data, setData, actions.handleChanges, 'value')
  const { elementOnLoadResources, elementValueChangeResources } = useWorkflowResources();

  const product = useProduct();
  const hasWorkflowAccess = product.hasProduct("WF")

  const handleOnChange = (e, type) => {
    const { name, value } = e.target;
    actions.handleChange(dialogDefinition, e, data.id, type)

    setProperty(data, name, value, type)
    setData(data => ({
      ...data
    }));
  }

  return (
    <div className="popover-container-items">

      <Property.Group
        title='Information'>
        <Property.Label
          label="Type"
          value="Map"
        />

        <Property.Text
          onChange={propertyToLabel.onChange}
          label={'Property'}
          name={'property'}
          value={data?.property}
        />

        <Property.Text
          onChange={labelUpdate.onChange}
          label={'Label'}
          name={'label'}
          value={data?.label}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Hide dropdown"}
          name={`map.hideDropdown`}
          value={data?.map?.hideDropdown}
          rowReverse
        />

      </Property.Group>
      <TextLicense hasAccess={hasWorkflowAccess} productName={"Workflow"} />
      <Property.Group title='Integration'>

        <Property.WorkflowSelect
          onChange={handleOnChange}
          label={"Workflow - Load map with data"}
          name={"workflowRetrieveLoadId"}
          value={data?.workflowRetrieveLoadId}
          options={elementOnLoadResources}
        />

        <Property.WorkflowSelect
          onChange={handleOnChange}
          label={"Workflow - When clicking a map item"}
          name={"workflowRetrieveId"}
          value={data?.workflowRetrieveId}
          options={elementValueChangeResources}
        />

      </Property.Group>

      <Property.Group title='Text Style'>

        <Property.Subgroup
          title="Label"
        >
          <Property.Color
            onChange={handleOnChange}
            label={'Color'}
            name={'labelStyle.color'}
            initColor={data?.labelStyle?.color}
          />

          <Property.Text
            onChange={handleOnChange}
            label={'Size'}
            name={'labelStyle.size'}
            type={'number'}
            value={data?.labelStyle?.size}
          />

          <Property.Text
            onChange={handleOnChange}
            label={'Width'}
            name={'labelStyle.width'}
            type={'number'}
            value={data?.labelStyle?.width}
          />

          <Property.FontStyleAndWeight
            onChange={handleOnChange}
            name="labelStyle"
            property={data?.labelStyle}
          />

          <Property.Select
            onChange={(e) => handleOnChange(e)}
            label={"Alignment"}
            name={"labelStyle.alignment"}
            value={data?.labelStyle?.alignment}
            options={alignmentOptions}
          />

        </Property.Subgroup>


      </Property.Group>
    </div>
  )
}
