import { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { Formik, Form } from 'formik';
import * as yup from "yup";
import { getDialogDefinition, getDialogDefinitionElements, updateDialogDefinitionProperties } from '../../../api/dialogDefinition';
import { FormikTextInput } from '../../../components/Form/Formik/FormikTextInput';
import { FormikSubmitButton } from '../../../components/Form/Formik/FormikSubmit';
import { useToastAction } from '../../../hooks/useToastAction';
import { Button } from '../../../components/Form/Button';
import Modal from '../../../components/Modal';
import { FormikCheckbox } from '../../../components/Form/Formik/FormikCheckbox';
import { FormikSelectInput } from '../../../components/Form/Formik/FormikSelect';
import './styles.scss';
import { Tags } from '../DialogDesign/tags';
import { processTypes } from '../../../utils/constants';
import { getActiveAuthenticationTemplates } from '../../../api/authenticationTemplates';
import { getFolders } from '../../../api/archive';
import { useDebounceCallback } from '@react-hook/debounce';
import { Reminders } from './reminders';
import { getTemplateEnvironments, getTemplates } from '../../../api/pdfConfigurations';
import { getRetentionPolicies } from '../../../api/retentionPolicies';
import { createRetentionPolicyOptions } from '../../../utils/dialogDefinitions';

export default function DialogConfiguration() {
  let { dialogKey } = useParams();
  const [dialogDefinition, setDialogDefinition] = useState()
  const [authenticationTemplateOptions, setAuthenticationTemplateOptions] = useState()
  const [pdfConfigurationEnvironmentOptions, setPdfConfigurationEnvironmentOptions] = useState([])
  const [pdfConfigurationTemplateOptions, setPdfConfigurationTemplateOptions] = useState([])
  const [elements, setElements] = useState();
  const [archviveFolders, setArchiveFolders] = useState();
  const [showElementsModal, setShowElementsModal] = useState(false);
  const submitAction = useToastAction(false, 'submit-action');
  const loadAction = useToastAction();
  const loadArchiveFoldersAction = useToastAction();
  const loadSchemaAction = useToastAction();
  const [retentionPoliciesOptions, setRetentionPoliciesOptions] = useState([])
  const [metaFiles, setMetaFiles] = useState([])
  const [selectedFont, setSelectedFont] = useState();

  const pdfStandardFontOptions = [
    { value: 'Arial', name: 'Arial', style: { fontFamily: "Arial" } },
    { value: 'Helvetica', name: 'Helvetica', style: { fontFamily: "Helvetica" } },
    { value: 'Calibri', name: 'Calibri', style: { fontFamily: "Calibri" } },
    { value: 'Futura', name: 'Futura', style: { fontFamily: "Futura" } },
    { value: 'Garamond', name: 'Garamond', style: { fontFamily: "Garamond" } },
    { value: 'Times New Roman', name: 'Times New Roman', style: { fontFamily: "Times New Roman" } },
    { value: 'Cambria', name: 'Cambria', style: { fontFamily: "Cambria" } },
    { value: 'Verdana', name: 'Verdana', style: { fontFamily: "Verdana" } },
    { value: 'Rockwell', name: 'Rockwell', style: { fontFamily: "Rockwell" } },
    { value: 'Franklin Gothic', name: 'Franklin Gothic', style: { fontFamily: "Franklin Gothic" } },
  ].sort((a, b) => a.name.localeCompare(b.name))

  const defaultOption = { value: 'null', name: 'None' }

  const getMetaFileOptions = (environment) => {

    let options = []
    options.push(defaultOption)

    metaFiles.forEach(metaFile => {
      if (metaFile.environment === environment) {
        options.push({
          value: metaFile.filename,
          name: metaFile.filename
        })
      }
    })

    return options || []
  }

  useEffect(() => {
    load()
    loadArchiveFolders()
    loadPdfConfigurations()
  }, [])

  useEffect(() => {
    let scrollToElement = window.location.hash.replace('#', '')

    if (scrollToElement) {
      let element = document.getElementById(scrollToElement);
      element?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }

    if (dialogDefinition) {
      loadPdfConfigurationTemplates()
    }
  }, [dialogDefinition])

  const loadPdfConfigurationTemplates = async (environment, metaFile) => {
    environment = environment || dialogDefinition?.pdfConfigurationEnvironment
    metaFile = metaFile || dialogDefinition?.pdfConfigurationMetafile

    const onlineDocument = await getTemplates(environment, metaFile);
    const templatesOptions = onlineDocument?.Root?.Documents?.filter(template => template.HasProcess).map(template => {
      return {
        value: template.Name,
        name: template.Name
      }
    })

    templatesOptions.unshift({
      value: null,
      name: 'None'
    })

    setPdfConfigurationTemplateOptions(templatesOptions);
  }

  async function loadArchiveFolders() {
    loadArchiveFoldersAction.execute(async () => {
      const result = await getFolders();

      let archiveFolderOptions = result.map(archiveFolder => {
        return {
          value: archiveFolder.id,
          name: `${archiveFolder.name} (${archiveFolder.fullPath})`
        }
      })

      setArchiveFolders(archiveFolderOptions);
    })
  }

  const loadPdfConfigurations = async () => {
    const templateEnvironments = await getTemplateEnvironments();

    let environmentsOptions = []
    environmentsOptions.push(defaultOption)

    let metaFilesTemp = []

    templateEnvironments.forEach(templateEnvironment => {
      environmentsOptions.push({
        value: templateEnvironment.metaData.CustomerEnvironment,
        name: templateEnvironment.metaData.CustomerEnvironment
      })

      templateEnvironment.files.forEach(file => {
        metaFilesTemp.push({
          filename: file.fileName,
          environment: templateEnvironment.metaData.CustomerEnvironment
        })
      })
    })

    setMetaFiles(metaFilesTemp)

    // const environments = await getEnvironments();
    // const environmentsOptions = environments.map(environment => {
    //   return {
    //     value: environment.metaData.CustomerEnvironment,
    //     name: environment.metaData.CustomerEnvironment
    //   }
    // }
    // )

    setPdfConfigurationEnvironmentOptions(environmentsOptions)
  }

  async function load() {
    loadAction.execute(async () => {
      const [dialogDefinitionResult, authenticationTemplatesResult, retentionPolicies] = await Promise.all([
        getDialogDefinition(dialogKey),
        getActiveAuthenticationTemplates(),
        getRetentionPolicies()
      ]);

      let authenticationTemplateOptionsCreated = authenticationTemplatesResult.map(authenticationTemplate => {
        return {
          value: authenticationTemplate.id,
          name: authenticationTemplate.name
        }
      })

      authenticationTemplateOptionsCreated.unshift({
        value: null,
        name: 'None'
      })
      const mapperRetentionPolicyOptions = createRetentionPolicyOptions(retentionPolicies, dialogDefinitionResult.retentionPolicy);
      setRetentionPoliciesOptions([{ name: 'None', value: '' }, ...mapperRetentionPolicyOptions])
      setAuthenticationTemplateOptions(authenticationTemplateOptionsCreated);
      setDialogDefinition(dialogDefinitionResult);
    }, "Failed to load")
  }

  const onOutpuSchemaClick = (evt) => {
    evt.preventDefault();
    loadSchemaAction.execute(async () => {
      setShowElementsModal(false);

      const elements = await getDialogDefinitionElements(dialogKey)

      setElements(elements);
      setShowElementsModal(true);
    }, "Failed to load");
  }

  const handlePdfConfigurationEngineChange = async (e) => {
    const { name, value } = e.target;
    debounced(e);

    // if(name === 'pdfConfigurationEnvironment') {
    //   debouncedLoadTemplates(e)
    // }

    if (name === 'pdfConfigurationMetafile') {
      debouncedLoadTemplates(e)
    }
  }

  const debounced = useDebounceCallback(
    (value) => {
      document.getElementById('save').click();
    },
    1000
  );

  const debouncedLoadTemplates = useDebounceCallback(
    (value) => {
      loadPdfConfigurationTemplates(null, value)
    },
    100
  );

  const onArchiveClick = (e, formikProps) => {
    const checked = e.target.checked;
    const name = e.target.name;

    const isHasSendToArchive = name === "hasSendToArchive";
    const isHasArchiveWhenCreated = name === "hasArchiveWhenCreated";

    const archiveFolderValue = formikProps.values.archiveFolder;

    //if either is checked and no folder is selected
    if (checked && (!archiveFolderValue || archiveFolderValue === "")) {
      formikProps.setFieldValue("archiveFolder", archviveFolders[0]?.value)
    }
    //if both are unchecked, leave archiveFolder set to null.
    else if ((isHasSendToArchive && !checked && !formikProps.values.hasArchiveWhenCreated)
      || (isHasArchiveWhenCreated && !checked && !formikProps.values.hasSendToArchive)) {
      formikProps.setFieldValue("archiveFolder", null)
    }
  }

  return (
    <>
      {
        dialogDefinition &&
        <>
          <Formik
            initialValues={{
              dialogName: dialogDefinition.name,
              category: dialogDefinition.metaData['Category'] || '',
              generatePdfOnSubmit: dialogDefinition.generatePdfOnSubmit,
              hasSendToArchive: dialogDefinition.hasSendToArchive,
              hasArchiveWhenCreated: dialogDefinition.hasArchiveWhenCreated,
              hasDigitalSigning: dialogDefinition.hasDigitalSigning,
              showSigningOnFormComplete: dialogDefinition.showSigningOnFormComplete,
              hasPowerOfAttorney: dialogDefinition.hasPowerOfAttorney,
              archiveFolder: dialogDefinition.archiveFolder,
              process: dialogDefinition.process || processTypes.normal,
              authenticationTemplate: dialogDefinition.authenticationTemplate || null,
              digitalSigningMessage: dialogDefinition.digitalSigningMessage,
              pdfConfigurationEngine: dialogDefinition.pdfConfigurationEngine,
              pdfStandardFont: dialogDefinition.pdfStandardFont,
              pdfConfigurationEnvironment: dialogDefinition.pdfConfigurationEnvironment,
              pdfConfigurationMetafile: dialogDefinition.pdfConfigurationMetafile,
              pdfConfigurationTemplate: dialogDefinition.pdfConfigurationTemplate,
              retentionPolicyId: dialogDefinition.retentionPolicyId
            }}
            validationSchema={() => {
              return yup.object().shape({
                dialogName: yup.string().required("Required"),
              });
            }}

            onSubmit={async (values, actions, setFieldValue) => {
              submitAction.execute(async () => {
                // Save dialog definition
                const dialogDefinitionResult = await updateDialogDefinitionProperties(dialogKey, {
                  name: values.dialogName,
                  category: values.category,
                  generatePdfOnSubmit: values.generatePdfOnSubmit,
                  hasSendToArchive: values.hasSendToArchive,
                  hasArchiveWhenCreated: values.hasArchiveWhenCreated,
                  hasDigitalSigning: values.hasDigitalSigning,
                  showSigningOnFormComplete: values.showSigningOnFormComplete,
                  hasPowerOfAttorney: values.hasPowerOfAttorney,
                  process: values.process,
                  digitalSigningMessage: values.digitalSigningMessage,
                  archiveFolder: values.archiveFolder,
                  authenticationTemplate: values.authenticationTemplate === 'None' ? null : values.authenticationTemplate,
                  pdfStandardFont: values.pdfStandardFont ? values.pdfStandardFont : 'Arial',
                  pdfConfigurationEngine: values.pdfConfigurationEngine === 'standard' ? null : values.pdfConfigurationEngine,
                  pdfConfigurationEnvironment: values.pdfConfigurationEnvironment === 'None' ? null : values.pdfConfigurationEnvironment,
                  pdfConfigurationMetafile: values.pdfConfigurationMetafile,
                  pdfConfigurationTemplate: values.pdfConfigurationTemplate,
                  retentionPolicyId: values.retentionPolicyId ? values.retentionPolicyId : null
                  // pdfConfigurationTemplate: values.pdfConfigurationTemplate === 'None' ? null : values.pdfConfigurationTemplate,
                });
                setDialogDefinition(dialogDefinitionResult);
              }, "Failed to save", "Saved")

              actions.setSubmitting(false);
            }}
          >
            {
              props => (
                <>
                  <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9 mt-5">
                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                      <div className="bg-white py-6 px-4 sm:p-6">
                        <div>
                          <h3 className="text-lg leading-6 font-medium text-gray-900">General</h3>
                        </div>
                        <Form onChange={debounced}>
                          <div className="mt-6 grid grid-cols-4 gap-6">
                            <div className="col-span-4 sm:col-span-2">
                              <FormikTextInput
                                label="Smartform name"
                                name="dialogName"
                                required={true}
                                formikProps={props}
                              />
                            </div>

                            <div className="col-span-4 sm:col-span-2">
                              <FormikTextInput
                                label="Category"
                                name="category"
                                formikProps={props}
                              />
                            </div>
                          </div>
                        </Form>
                        <div className="mt-6 grid grid-cols-4 gap-6">
                          <div className="col-span-4 sm:col-span-2">
                            <label
                              htmlFor="tags"
                              className="block text-sm font-medium text-gray-700 mb-1"
                            >
                              Tags
                            </label>
                            <Tags dialogDefinitionId={dialogKey} />
                          </div>

                          <div className="col-span-4 sm:col-span-2">
                            <Form onChange={debounced}>
                              <FormikSelectInput
                                label="Authentication Template"
                                onChange={e => { }}
                                name="authenticationTemplate"
                                formikProps={props}
                                options={authenticationTemplateOptions}
                              />
                            </Form>
                          </div>
                          <div className="col-span-4 sm:col-span-2">
                            <Form onChange={debounced}>
                              <FormikSelectInput
                                label="Retention Policy"
                                onChange={e => { }}
                                name="retentionPolicyId"
                                formikProps={props}
                                options={retentionPoliciesOptions}
                              />
                            </Form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9 mt-10 relative">
                    <div className="shadow sm:rounded-md">
                      <div className="bg-white py-6 px-4 sm:p-6">
                        <div>
                          <h3 className="text-lg leading-6 font-medium text-gray-900">Pdf Configuration</h3>
                        </div>
                        <Form onChange={handlePdfConfigurationEngineChange}>
                          <div className="mt-6 grid grid-cols-5 gap-6">
                            <FormikSelectInput
                              label="Engine"
                              onChange={e => { }}
                              name="pdfConfigurationEngine"
                              formikProps={props}
                              options={[
                                { value: 'standard', name: 'Standard' },
                                { value: 'interact', name: 'Interact' },
                                { value: 'centerpoint', name: 'Centerpoint' },
                              ]}
                              submitInChange
                            />

                            {
                              props.values.pdfConfigurationEngine === 'interact' ?
                                <>
                                  <FormikSelectInput
                                    label="Environment"
                                    onChange={e => { }}
                                    name="pdfConfigurationEnvironment"
                                    formikProps={props}
                                    options={pdfConfigurationEnvironmentOptions}
                                    submitInChange
                                  />

                                  <FormikSelectInput
                                    label="Metafile"
                                    onChange={e => { }}
                                    name="pdfConfigurationMetafile"
                                    formikProps={props}
                                    options={props.values.pdfConfigurationEnvironment ? getMetaFileOptions(props.values.pdfConfigurationEnvironment) : []}
                                    submitInChange
                                  />

                                  {/* <FormikTextInput
                                  label="Metafile"
                                  name="pdfConfigurationMetafile"
                                  formikProps={props}
                                /> */}

                                  {/* <FormikTextInput
                                  label="Template"
                                  name="pdfConfigurationTemplate"
                                  formikProps={props}
                                /> */}

                                  <FormikSelectInput
                                    label="Template"
                                    onChange={e => { }}
                                    required={true}
                                    name="pdfConfigurationTemplate"
                                    formikProps={props}
                                    options={pdfConfigurationTemplateOptions}
                                    submitInChange
                                  />
                                </>
                                :
                                props.values.pdfConfigurationEngine === 'centerpoint' ?
                                <></>
                                  // <FormikSelectInput
                                  //   label="Environment"
                                  //   onChange={e => { }}
                                  //   name="pdfConfigurationEnvironment"
                                  //   formikProps={props}
                                  //   options={
                                  //     [
                                  //       { value: 'production', name: 'Production' },
                                  //       { value: 'test', name: 'Test' },
                                  //       { value: 'development', name: 'Development' },
                                  //     ]
                                  //   }
                                  //   submitInChange
                                  // />            
                                  :
                                (
                                  <>
                                    <FormikSelectInput
                                      label="Font"
                                      onChange={e => { }}
                                      name="pdfStandardFont"
                                      formikProps={props}
                                      options={pdfStandardFontOptions}
                                      submitInChange
                                    />

                                    <div className='w-full ml-5 mt-3 flex items-center justify-center'>
                                      <label className='text-xl font-medium' style={{ fontFamily: props.values.pdfStandardFont }}>Preview Text in {props.values?.pdfStandardFont ?? "Arial"}</label>
                                    </div>
                                  </>
                                )
                            }

                            {/* <div className='inline-flex'>
                              <Button
                                text="View"
                                onClick={onOutpuSchemaClick}
                              />
                            </div> */}
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>

                  <Form onChange={debounced}>
                    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9 mt-10">
                      <div className="shadow sm:rounded-md">
                        <div className="bg-white py-6 px-4 sm:p-6">
                          <div>
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Archive</h3>
                          </div>

                          <div className="mt-6 grid grid-cols-4 gap-6">

                            <div className="col-span-4 sm:col-span-1">
                              <FormikCheckbox
                                label="Archive"
                                secondaryLabel="Send Smartform to Archive"
                                name="hasSendToArchive"
                                formikProps={props}
                                onClick={(e) => onArchiveClick(e, props)}

                                styles="focus:ring-gray-400 focus:border-gray-400 h-4 w-4 text-indigo-600 rounded"
                              />
                            </div>

                            <div className="col-span-4 sm:col-span-1">
                              <FormikCheckbox
                                label="Archive when created"
                                secondaryLabel="Archive Smartform when created"
                                name="hasArchiveWhenCreated"
                                formikProps={props}
                                styles="focus:ring-gray-400 focus:border-gray-400 h-4 w-4 text-indigo-600 rounded"
                                onClick={(e) => onArchiveClick(e, props)}
                              />
                            </div>

                          </div>

                          {
                            (props.values.hasSendToArchive || props.values.hasArchiveWhenCreated) &&
                            <div className="mt-6 grid grid-cols-4 gap-6">
                              <div className="col-span-4 sm:col-span-2">
                                <FormikSelectInput
                                  label="Folder"
                                  onChange={e => { }}
                                  name="archiveFolder"
                                  formikProps={props}
                                  options={archviveFolders}
                                  submitInChange
                                />
                              </div>
                            </div>
                          }

                        </div>
                      </div>
                    </div>

                    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9 mt-10">
                      <div className="shadow sm:rounded-md sm:overflow-hidden">
                        <div className="bg-white py-6 px-4 sm:p-6">
                          <div>
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Other Configurations</h3>
                          </div>

                          <div className="mt-6 grid grid-cols-4 gap-6">
                            <div className="col-span-4 sm:col-span-1">
                              <FormikCheckbox
                                label="PDF"
                                secondaryLabel="Generate a PDF of the Smartform when submitted."
                                name="generatePdfOnSubmit"
                                formikProps={props}
                                styles="focus:ring-gray-400 focus:border-gray-400 h-4 w-4 text-indigo-600 rounded"
                              />
                            </div>

                            <div className="col-span-4 sm:col-span-1">
                              <FormikCheckbox
                                label="Has Power of Attorney"
                                secondaryLabel="Has Power of Attorney."
                                name="hasPowerOfAttorney"
                                formikProps={props}
                                styles="focus:ring-gray-400 focus:border-gray-400 h-4 w-4 text-indigo-600 rounded"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="pt-2 flex justify-end hidden">
                      <FormikSubmitButton
                        id="save"
                        text="Save"
                        className="i"
                        formikProps={props}
                      />
                    </div>
                  </Form>
                </>
              )
            }
          </Formik>

          <Reminders dialogKey={dialogKey} />

          <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9 mt-10 pb-20">
            <div className="shadow sm:rounded-md sm:overflow-hidden">
              <div className="bg-white py-6 px-4 sm:p-6">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Schema</h3>
                </div>

                <div className="mt-6 grid grid-cols-4 gap-6">
                  <div className="col-span-4 sm:col-span-2">
                    <div className="flex gap-x-6">
                      <p className="mt-1 text-gray-800">View the data schema for the dialog.</p>
                      <Button
                        text="View"
                        onClick={onOutpuSchemaClick}
                      />
                    </div>
                  </div>
                </div>
                <div className='flex justify-around'>
                  <Modal
                    isOpen={!loadSchemaAction.isExecuting && showElementsModal}
                    onClose={() => setShowElementsModal(false)}
                    size="large"
                  >
                    <div className="mt-4">
                      <label className="block text-sm font-medium text-gray-700 mb-2">Elements output</label>
                      <textarea
                        rows="25"
                        style={{ width: "100%" }}
                        value={JSON.stringify(elements, null, '\t')}
                        readOnly={true}
                      />
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </>
      }

      {
        !loadAction.isExecuting && !dialogDefinition &&
        <h1>Could not find dialog definition. Or no access.</h1>
      }
    </>
  )
}